
;

// ================ Action types ================ //

export const FETCH_ORDERS_REQUEST = 'app/PanelPage/FETCH_ORDERS_REQUEST';
export const FETCH_ORDERS_SUCCESS = 'app/PanelPage/FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_ERROR = 'app/PanelPage/FETCH_ORDERS_ERROR';

export const FETCH_SALES_REQUEST = 'app/PanelPage/FETCH_SALES_REQUEST';
export const FETCH_SALES_SUCCESS = 'app/PanelPage/FETCH_SALES_SUCCESS';
export const FETCH_SALES_ERROR = 'app/PanelPage/FETCH_SALES_ERROR';

// ================ Reducer ================ //



export default function adobePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SALES_REQUEST:
      return { ...state, salesInProgress: true, fetchSalesError: null };

    case FETCH_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        salesRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };
  }
}

// ================ Action creators ================ //

const fetchOrdersRequest = () => ({ type: FETCH_ORDERS_REQUEST });
const fetchOrdersSuccess = response => ({
  type: FETCH_ORDERS_SUCCESS,
  payload: response,
});
const fetchOrdersError = e => ({
  type: FETCH_ORDERS_ERROR,
  error: true,
  payload: e,
});

const fetchSalesRequest = () => ({ type: FETCH_SALES_REQUEST });
const fetchSalesSuccess = response => ({
  type: FETCH_SALES_SUCCESS,
  payload: response,
});
const fetchSalesError = e => ({
  type: FETCH_SALES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //


export const loadData = (params, search) => (dispatch, getState, sdk) => {

    
};
