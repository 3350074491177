import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, FieldTextInput, Button, NamedLink, IconCard } from '../../components';
import AppleLogin from 'react-apple-login';

import suzukii from './Grupo -8.png';
import facebook from './facebook.jpg';
import google from './google.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { pushToPath } from '../../util/urlHelpers';
import car from './005-car.png';
import pickup from './001-pick-up-truck.png';
import family from './002-family-car.png';
import limousine from './006-limousine.png';
import electric from './007-electric-car.png';

import css from './SignupForm.module.css';


const SignupFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        authWithGoogle,
        authWithFacebook,
        intl,
        appleLoginCallback,
        appleSignUpError,
        loginOrSignupError,
        // history,
        // onOpenTermsOfService,
      } = fieldRenderProps;

      const [checked, setChecked] = useState(false);
      // email
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password

      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      // firstName

      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName

      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;
      const renderAppleButton = (props) => (
        <button type='button' className={css.appleSignUp} {...props}>
          <span>
            <span className={css.appleLogo}>
              <IconCard brand="applelogo" />
            </span>
            <span className={css.appleButton}>
              Sign Up with Apple
            </span>
          </span>
          <span>
            <ArrowForwardIcon sx={{ color: '#ffffff' }} />
          </span>
        </button>
      );

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <div className={css.rowWrapper}>
              <div className={css.inputWrapper}>
                <div className={css.mobile}>
                  <p className={css.mobileText}>Crear cuenta</p>
                </div>
                <div className={css.border} onClick={authWithGoogle} >
                  <div className={css.row}>
                    <img src={google} className={css.google} alt='google' />
                    <p>Ingresar con Google</p>
                    <ArrowForwardIcon sx={{ color: '#ACACAC' }} />
                  </div>
                </div>

                <div className={css.appleLogin}>
                  <AppleLogin
                    clientId={"com.rentalo.rent.service"}
                    redirectURI={process.env.REACT_APP_CANONICAL_ROOT_URL}
                    responseType={"id_token"}
                    responseMode={"form_post"}
                    scope={"name email"}
                    usePopup={true}
                    callback={appleLoginCallback}
                    render={renderAppleButton}
                  />
                </div>
                <div className={css.border} onClick={authWithFacebook}>
                  <div className={css.row}>
                    <img src={facebook} className={css.google} alt='facebook' />
                    <p>Ingresar con Facebook</p>
                    <ArrowForwardIcon sx={{ color: '#ACACAC' }} />
                  </div>
                </div>
                <div className={css.box}>
                  <p className={css.text1}>Ingrese con su correo electrónico y contraseña</p>
                  <div className={css.name}>
                    <FieldTextInput
                      className={css.firstNameRoot}
                      type="text"
                      id={formId ? `${formId}.fname` : 'fname'}
                      name="fname"
                      autoComplete="given-name"
                      placeholder="Nombre"
                      validate={firstNameRequired}
                    />
                    <FieldTextInput
                      className={css.lastNameRoot}
                      type="text"
                      id={formId ? `${formId}.lname` : 'lname'}
                      name="lname"
                      autoComplete="family-name"

                      placeholder="Apellido"
                      validate={lastNameRequired}
                    />
                  </div>
                  <FieldTextInput
                    className={css.email}
                    type="email"
                    id={formId ? `${formId}.email` : 'email'}
                    name="email"
                    autoComplete="email"

                    placeholder="Correo"
                    validate={validators.composeValidators(emailRequired, emailValid)}
                  />
                  <FieldTextInput
                    className={css.password}
                    type="password"
                    id={formId ? `${formId}.password` : 'password'}
                    name="password"
                    autoComplete="new-password"

                    placeholder="Contraseña"
                    validate={passwordValidators}
                  />
                  <div>
                    <p style={{ color: "red" }}>{appleSignUpError}</p>
                    <p style={{ color: "red" }}>{loginOrSignupError}</p>
                  </div>
                  <div className={css.form}>
                    <input
                      type="checkbox"
                      defaultChecked={checked}
                      onChange={() => setChecked(!checked)}
                    />
                    <label>
                      Acepto los{' '}
                      <NamedLink name="TermsOfServicePage">términos y condiciones</NamedLink>
                    </label>
                  </div>

                  {checked === true ? (
                    <Button
                      className={css.button}
                      type="submit"
                      inProgress={submitInProgress}
                      disabled={submitDisabled}
                    >

                      Crear cuenta
                    </Button>
                  ) : (
                    <Button
                      className={css.button}
                      type="submit"
                      inProgress={submitInProgress}
                      disabled
                    >

                      Crear cuenta
                    </Button>
                  )}
                  <p className={css.text} onClick={() => pushToPath('/login')}>
                    Iniciar sesión
                  </p>
                </div>

                <div className={css.row}>
                  <div className={css.carBox}>
                    <img src={car} className={css.car} alt='coche' />
                  </div>
                  <div className={css.carBox}>
                    <img src={limousine} className={css.car} alt='limusina' />
                  </div>
                  <div className={css.carBox}>
                    <img src={family} className={css.car} alt='familia' />
                  </div>
                  <div className={css.carBox}>
                    <img src={electric} className={css.car} alt='eléctrico' />
                  </div>
                  <div className={css.carBox}>
                    <img src={pickup} className={css.car} alt='camioneta' />
                  </div>
                </div>
              </div>

              <div className={css.formDetailsRight}>
                <div className={css.box1}>
                  <h1 className={css.title1}>Renta tu vehículo favorito</h1>
                  <p className={css.pTag}>
                    <strong>Réntalo®</strong>
                    <span className={css.text1}>
                      {' '}
                      Es la plataforma de alquiler de autos más novedosa, segura y rentable. En Réntalo si eres dueño de un vehículo o una flota completa puedes rentarlo a ciudadanos y turistas de todo el mundo. El viaje estará asegurado por nuestra póliza de seguro para tranquilidad del propietario y el conductor.
                    </span>
                  </p>
                  <img src={suzukii} className={css.pic} alt='suzukii' />
                </div>
              </div>
            </div>

          </div>
          <br />

        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
